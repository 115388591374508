module.exports = [{
      plugin: require('/Users/pedro/work/3Decide/aliveVision/Code/int_alivevision_website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/pedro/work/3Decide/aliveVision/Code/int_alivevision_website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-115175661-1"},
    },{
      plugin: require('/Users/pedro/work/3Decide/aliveVision/Code/int_alivevision_website/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1080},
    },{
      plugin: require('/Users/pedro/work/3Decide/aliveVision/Code/int_alivevision_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
